/**
 * init plugins
 */
initPlugins=function(){
  //algunos plugins interesa solo inicializarlos en mobile
  if (anchoventana>=md){
      /*animaciones WOW*/
        new WOW().init();

      /* scroll parallax */
        //var s = skrollr.init();          
  }

  /* personaliza el check con un ON-OFF */
  var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
  elems.forEach(function(html) {
    var switchery = new Switchery(html,{
      color : '#f1426e'
    });
  });

  /* personaliza un slider */
  $("#slide").ionRangeSlider({
      type: "single",
      min: 0,
      max: 50,
      from: 1,
      step: 5,
      grid: true,
      grid_snap: true,
      values:["1","10","20","30","40","50"],
      grid_num: 5,
      postfix: "km"
  });

  /* el combo a un formato con más opciones */
  $(".chosen-select").chosen({
    disable_search_threshold: 10,
    placeholder_text_single: "Selecciona opción",
    placeholder_text_single: "Selecciona opción",
  });
 
  /* personaliza el check */
  $('.i-checks').iCheck({
    checkboxClass: 'icheckbox_square-green',
    radioClass: 'iradio_square-green',
  });

  /* personaliza un campo numerico con + y - */
  $(".touchspin").TouchSpin({
      buttondown_class: 'btn btn-white',
      buttonup_class: 'btn btn-white',
      min:1,
      max:100
  });

  /* carrusel swiper */
  var swiper = new Swiper('.carrusel', {
      pagination: '.swiper-pagination',
      slidesPerView: 1,
      autoplay: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
  });

  // var swiper = new Swiper('.swiper-container', {
  // // Default parameters
  // slidesPerView: 6,
  // spaceBetween: 40,
  // autoplay: true,
  // loop: true,
  // // Responsive breakpoints
  // breakpoints: {
  //   // when window width is <= 320px
  //   320: {
  //     slidesPerView: 2,
  //     spaceBetween: 10
  //   },
  //   // when window width is <= 480px
  //   480: {
  //     slidesPerView: 2,
  //     spaceBetween: 20
  //   },
  //   // when window width is <= 640px
  //   640: {
  //     slidesPerView: 3,
  //     spaceBetween: 30
  //   }
  // }
  // });





};